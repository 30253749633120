import { render, staticRenderFns } from "./FaqList.vue?vue&type=template&id=ad2eaac8&"
import script from "./FaqList.vue?vue&type=script&lang=js&"
export * from "./FaqList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarkdownBlock: require('/server/itg-custom/staging/front/components/MarkdownBlock.vue').default})
